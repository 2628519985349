export const images = [
    {
      src: "https://imagedelivery.net/tfkBNFKjA8IypktO2hmB1A/71515d00-5e9a-4450-15fb-f33769e55400/large2500",
      width: 2100,
      height: 1400,
      alt: "A black bear stands on all four legs with its head facing the camera.",
    },
    {
      src: "https://imagedelivery.net/tfkBNFKjA8IypktO2hmB1A/f7194361-7d2c-4d92-d7fc-5a696a835c00/large2500",
      width: 2100,
      height: 1400,
      alt: "A waterfall flows behind stone stairs, leading upwards toward a light flare."
    },
    {
      src: "https://imagedelivery.net/tfkBNFKjA8IypktO2hmB1A/836ddc99-7808-43f2-935d-b1b383d5cc00/large2500",
      width: 2100,
      height: 1400,
      alt: "Yosemite's El Capitan peeks out above evergreen trees in morning light."
    },
    {
      src: "https://imagedelivery.net/tfkBNFKjA8IypktO2hmB1A/da9c2666-6444-491f-fc61-52442b83ab00/large2500",
      width: 9798,
      height: 5986,
      alt: "Silhouettes of mountains over the water during sunset with a pink hue."
    },
    {
      src: "https://imagedelivery.net/tfkBNFKjA8IypktO2hmB1A/6a4c7c02-ae3a-40f8-99ef-2ae0a2194a00/large2500",
      width: 5000,
      height: 3333,
      alt: "A hiker stands on a trail between evergreen trees on the left and sharp, beige rocks on the right."
    },
    {
      src: "https://imagedelivery.net/tfkBNFKjA8IypktO2hmB1A/24e128fa-8b1e-4ccd-3e31-c98bbaee7300/large2500",
      width: 5509,
      height: 3673,
      alt: "Several brown seals sitting on a wet rock play with their mouths open"
    },
    {
      src: "https://imagedelivery.net/tfkBNFKjA8IypktO2hmB1A/6d6937ea-078c-4572-c59b-f0566fb74d00/large2500",
      width: 4787,
      height: 3190,
      alt: "A black bear stands on a rock in a river, holding a salmon in its mouth."
    },
    {
      src: "https://imagedelivery.net/tfkBNFKjA8IypktO2hmB1A/17ddf03d-01cb-495b-40a3-251de5b6fa00/large2500",
      width: 4531,
      height: 3021,
      alt: "A whale tale dives into the water with water streaming off the back."
    },
    {
      src: "https://imagedelivery.net/tfkBNFKjA8IypktO2hmB1A/0b15ded8-a8f3-4553-7f77-49b30d91c300/large2500",
      width: 6034,
      height: 2924,
      alt: "The teton mountains rise over a bright grassy field"
    },
    {
      src: "https://imagedelivery.net/tfkBNFKjA8IypktO2hmB1A/f49f12ad-d335-4a04-1c59-04779e1ae700/large2500",
      width: 5000,
      height: 3333,
      alt: "A silhouette of a large Joshua Tree is backlit in a desert landscape at sunset."
    },
    {
      src: "https://imagedelivery.net/tfkBNFKjA8IypktO2hmB1A/b10bc60a-c74c-4aa8-4f80-1b40c9242d00/large2500",
      width: 5000,
      height: 3333,
      alt: "A stone arch in Joshua Tree national park."
    },
    {
      src: "https://imagedelivery.net/tfkBNFKjA8IypktO2hmB1A/31e233ac-ef1d-4ba9-cdfa-1f6c2662c700/large2500",
      width: 1440,
      height: 1080,
      alt: "A dirt path cutting through grass toward the right, with the pacific coast and beach on the left."
    },
    {
      src: "https://imagedelivery.net/tfkBNFKjA8IypktO2hmB1A/c18a3f39-29d6-478c-724a-3423dc6ebc00/large2500",
      width: 4194,
      height: 2796,
      alt: "Evergreen trees right on the edge of the water, with mountains in the background, near Sitka, Alaska."
    },
    {
      src: "https://imagedelivery.net/tfkBNFKjA8IypktO2hmB1A/0a0ff059-fce1-4b6d-72e9-2ae78434e400/large2500",
      width: 2500,
      height: 1667,
      alt: "A white goat sticks its head through a wire fence"
    },
    {
      src: "https://imagedelivery.net/tfkBNFKjA8IypktO2hmB1A/24a26526-d429-4f22-70f3-3b2f12a28e00/large2500",
      width: 2500,
      height: 1667,
      alt: "A individual in silhouette stands in front of snowy mountains on the top of Whistler mountain"
    },
    {
      src: "https://imagedelivery.net/tfkBNFKjA8IypktO2hmB1A/5a25518f-c91b-46f4-14fd-b5351aecd900/large2500",
      width: 2560,
      height: 1400,
      alt: "A bridge in front of a ridge of snow-capped mountains in Mt. Cook national park."
    }
]
  
export const sportsImages = [
  {
    src: "https://imagedelivery.net/tfkBNFKjA8IypktO2hmB1A/0f3af151-cfdd-4e7d-0f14-aa1ea777e000/large2500",
    width: 2500,
    height: 1669,
    alt: "",
    name: "NU football player diving into end zone"
  },
  {
    src: "https://imagedelivery.net/tfkBNFKjA8IypktO2hmB1A/eec434b5-9c26-42a9-9d03-00277c319f00/large2500",
    width: 2500,
    height: 1667,
    alt: "",
    name: "Courtney shaw clapping"
  },
  {
    src: "https://imagedelivery.net/tfkBNFKjA8IypktO2hmB1A/3ce78691-0ad5-430c-dfa7-a7044efde800/large2500",
    width: 2500,
    height: 1667,
    alt: "",
    name: "Softball helmet glare"
  },
  {
    src: "https://imagedelivery.net/tfkBNFKjA8IypktO2hmB1A/c55f5951-e83a-4032-e5e6-fae6cb102f00/large2500",
    width: 2500,
    height: 1667,
    alt: "",
    name: "Northwestern football player being tackled"
  },
  {
    src: "https://imagedelivery.net/tfkBNFKjA8IypktO2hmB1A/5e75b4a8-9572-4b09-1f3f-9787c35e5b00/large2500",
    width: 6156,
    height: 3441,
    alt: "",
    name: "go blue banner"
  },
  {
    src: "https://imagedelivery.net/tfkBNFKjA8IypktO2hmB1A/9fb4d1cf-d548-497e-2b55-0ba03130b700/large2500",
    width: 2500,
    height: 1667,
    alt: "",
    name: "Volleyball players celebrating"
  },
  {
    src: "https://imagedelivery.net/tfkBNFKjA8IypktO2hmB1A/48aa7031-c6c6-41ab-8c47-a4ba37aaec00/large2500",
    width: 2500,
    height: 1667,
    alt: "",
    name: "Brooks Barnheizer celebrating"
  },
  {
    src: "https://imagedelivery.net/tfkBNFKjA8IypktO2hmB1A/9d9c2130-d89a-484a-bd5d-2a497dc2b500/large2500",
    width: 2500,
    height: 1667,
    alt: "",
    name: "Field hockey celebration"
  },
  {
    src: "https://imagedelivery.net/tfkBNFKjA8IypktO2hmB1A/d008963d-bcea-439e-946c-c57783fe2f00/large2500",
    width: 2500,
    height: 1667,
    alt: "",
    name: "Swimming breathstroke"
  },
  {
    src: "https://imagedelivery.net/tfkBNFKjA8IypktO2hmB1A/f8749571-506f-41e8-328b-88b596c1a600/large2500",
    width: 2500,
    height: 1667,
    alt: "",
    name: "Softball tagging"
  },
  {
    src: "https://imagedelivery.net/tfkBNFKjA8IypktO2hmB1A/32e5665a-d8a9-41f2-64b9-aefba0cfbd00/large2500",
    width: 2500,
    height: 1667,
    alt: "",
    name: "football staring"
  },
  {
    src: "https://imagedelivery.net/tfkBNFKjA8IypktO2hmB1A/38df1235-d240-4586-bdba-8e449e964d00/large2500",
    width: 5132,
    height: 2540,
    alt: "",
    name: "Field Hockey line"
  },
  {
    src: "https://imagedelivery.net/tfkBNFKjA8IypktO2hmB1A/7bbcac10-3a91-4205-8966-7cbaba7bc600/large2500",
    width: 2500,
    height: 1667,
    alt: "",
    name: "Julian roper celebrating"
  },
  {
    src: "https://imagedelivery.net/tfkBNFKjA8IypktO2hmB1A/f5bca636-c5e3-4cf3-2242-29c2b4e79600/large2500",
    width: 2500,
    height: 1667,
    alt: "",
    name: "lax player shooting"
  },
  {
    src: "https://imagedelivery.net/tfkBNFKjA8IypktO2hmB1A/4687a47f-9b58-4eff-a58a-006365ef2b00/large2500",
    width: 2500,
    height: 1667,
    alt: "",
    name: "michigan players hugging"
  },
  {
    src: "https://imagedelivery.net/tfkBNFKjA8IypktO2hmB1A/092de713-80ab-464d-41f9-43fd33372000/large2500",
    width: 2500,
    height: 1667,
    alt: "",
    name: "lindsey shooting layup"
  },
  {
    src: "https://imagedelivery.net/tfkBNFKjA8IypktO2hmB1A/3cc14c4b-b935-45b1-5332-a8ba4c468200/large2500",
    width: 2500,
    height: 1667,
    alt: "",
    name: "michigan football player running"
  }
]
